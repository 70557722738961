"use client"

import { getJwtToken, removeJwtToken } from "@/cookies/jwtCookieHelper"
import { ErrorResponse } from "@/interfaces/ErrorResponse"
import { useMessageSnackbarStore } from "@/stores/ui/useMessageSnackbar"
import axios, { AxiosError, AxiosRequestConfig } from "axios"

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  suppressErrorSnackbar?: boolean
}

export const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  headers: { "Access-Control-Allow-Origin": "*" },
})

api.interceptors.request.use((request) => {
  const token = getJwtToken()
  if (token) {
    request.headers.Authorization = `Bearer ${token}`
  }
  return request
})

api.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const config = error.config as CustomAxiosRequestConfig
    const suppressErrorSnackbar = config?.suppressErrorSnackbar

    return handleApiErrors(error, suppressErrorSnackbar)
  },
)

async function handleApiErrors(error: AxiosError, suppressErrorSnackbar?: boolean) {
  if (error.response) {
    const status = error.response.status

    if (status === 401) {
      removeJwtToken()
      if (!suppressErrorSnackbar) {
        useMessageSnackbarStore
          .getState()
          .errorMessage("Sua sessão expirou. Por favor, faça login novamente.")
      }
      return Promise.reject(error)
    }

    if (status === 404) {
      error.response.data = null
    }

    const message = getErrorMessageFromResponseData(error.response.data as ErrorResponse)
    if (!suppressErrorSnackbar) {
      useMessageSnackbarStore.getState().errorMessage(message)
    }
  } else if (!suppressErrorSnackbar) {
    useMessageSnackbarStore
      .getState()
      .errorMessage("Um erro inesperado ocorreu. Por favor, tente novamente.")
  }

  return Promise.reject(error)
}

export function getErrorMessageFromResponseData(
  responseData: ErrorResponse | null | undefined,
): string {
  if (!responseData) {
    return "Ocorreu um erro inesperado. Os detalhes da resposta não estão disponíveis."
  }

  if (!responseData.invalidParams) {
    return `${responseData.title}\n${responseData.detail}`
  }

  const errors = responseData.invalidParams.map((e) => e.reason).join(", ")
  return `${responseData.title}: ${errors}`
}

export function showErrorMessage(message: string) {
  useMessageSnackbarStore.getState().errorMessage(message)
}

export function showSuccessMessage(message: string) {
  useMessageSnackbarStore.getState().successMessage(message)
}

export const fetcher = async (url: string, options = {}) => {
  const response = await api.get(url, options)
  return response.data
}

export const authenticatedFetcher = async (url: string, options = {}) => {
  const token = getJwtToken()

  if (!token) {
    console.error(" Nenhum token encontrado! Bloqueando a requisição SWR.")
    return Promise.reject(new Error("Unauthorized - Nenhum token encontrado"))
  }

  const response = await api.get(url, {
    ...options,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return response.data
}

import { getRefreshToken, setJwtToken, setRefreshToken } from "@/cookies/jwtCookieHelper"
import { api, getErrorMessageFromResponseData, showErrorMessage } from "@/libs/api"
import { AxiosError } from "axios"

let isRefreshing = false

export async function refreshToken() {
  if (isRefreshing) {
    return
  }

  isRefreshing = true

  try {
    const data = {
      refresh_token: getRefreshToken(),
    }

    const response = await api.post(
      `${process.env.NEXT_PUBLIC_AUTH_SERVICE_PATH}/user/refresh-token`,
      data,
    )

    if (response.status === 200) {
      setJwtToken(response.data.access_token)
      setRefreshToken(response.data.refresh_token)
    }

    return { ...response.data }
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      const message = getErrorMessageFromResponseData(error.response.data)
      showErrorMessage(message)
    } else {
      showErrorMessage("Um erro inesperado ocorreu. Por favor tente novamente.")
    }
  } finally {
    isRefreshing = false
  }
}

import { AlertColor, AlertPropsColorOverrides } from "@mui/material"
import { OverridableStringUnion } from "@mui/types"
import { create } from "zustand"

type AlertSeverity = OverridableStringUnion<AlertColor, AlertPropsColorOverrides>

type SnackbarMessage = {
  id: number
  message: string
  severity: AlertSeverity
}

type Store = {
  messages: SnackbarMessage[]
  maxStack: number
  lastMessageTimesByContent: Record<string, number | undefined>

  successMessage: (message: string) => void
  errorMessage: (message: string) => void
  infoMessage: (message: string) => void
  warningMessage: (message: string) => void

  removeMessage: (id: number) => void
}

const DELAY = 2000

export const useMessageSnackbarStore = create<Store>((set) => ({
  messages: [],
  maxStack: 2,
  lastMessageTimesByContent: {},

  successMessage: (message: string) =>
    set((state) => {
      const now = Date.now()
      if (
        state.lastMessageTimesByContent[message] &&
        now - state.lastMessageTimesByContent[message]! < DELAY
      ) {
        return state
      }
      return {
        messages: [
          ...state.messages.slice(-state.maxStack),
          { id: now, message, severity: "success" },
        ],
        lastMessageTimesByContent: { ...state.lastMessageTimesByContent, [message]: now },
      }
    }),

  errorMessage: (message: string) =>
    set((state) => {
      const now = Date.now()
      if (
        state.lastMessageTimesByContent[message] &&
        now - state.lastMessageTimesByContent[message]! < DELAY
      ) {
        return state
      }
      return {
        messages: [
          ...state.messages.slice(-state.maxStack),
          { id: now, message, severity: "error" },
        ],
        lastMessageTimesByContent: { ...state.lastMessageTimesByContent, [message]: now },
      }
    }),

  infoMessage: (message: string) =>
    set((state) => {
      const now = Date.now()
      if (
        state.lastMessageTimesByContent[message] &&
        now - state.lastMessageTimesByContent[message]! < DELAY
      ) {
        return state
      }
      return {
        messages: [
          ...state.messages.slice(-state.maxStack),
          { id: now, message, severity: "info" },
        ],
        lastMessageTimesByContent: { ...state.lastMessageTimesByContent, [message]: now },
      }
    }),

  warningMessage: (message: string) =>
    set((state) => {
      const now = Date.now()
      if (
        state.lastMessageTimesByContent[message] &&
        now - state.lastMessageTimesByContent[message]! < DELAY
      ) {
        return state
      }
      return {
        messages: [
          ...state.messages.slice(-state.maxStack),
          { id: now, message, severity: "warning" },
        ],
        lastMessageTimesByContent: { ...state.lastMessageTimesByContent, [message]: now },
      }
    }),

  removeMessage: (id: number) =>
    set((state) => ({
      messages: state.messages.filter((msg) => msg.id !== id),
    })),
}))

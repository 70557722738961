import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"

interface OfflineContextType {
  isOffline: boolean
  setIsOffline: React.Dispatch<React.SetStateAction<boolean>>
}

const OfflineContext = createContext<OfflineContextType | undefined>(undefined)

export function OfflineProvider({ children }: Readonly<{ children: ReactNode }>) {
  const [isOffline, setIsOffline] = useState<boolean>(false)

  useEffect(() => {
    const handleOnline = () => setIsOffline(false)
    const handleOffline = () => setIsOffline(true)

    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    if (!navigator.onLine) {
      setIsOffline(true)
    }

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [])

  const value = useMemo(
    () => ({
      isOffline,
      setIsOffline,
    }),
    [isOffline, setIsOffline],
  )

  return <OfflineContext.Provider value={value}>{children}</OfflineContext.Provider>
}

export const useOffline = (): OfflineContextType => {
  const context = useContext(OfflineContext)

  if (!context) {
    throw new Error("useOffline must be used within an OfflineProvider")
  }

  return context
}
export default OfflineContext

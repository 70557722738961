"use client"

import { Box, BoxProps, Button, CircularProgress, Typography } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"

export interface LoadingAnimationProps {
  text?: string
  timeout?: number
  onTimeout?: () => void
  isVisible?: boolean
  autoRetry?: boolean
}

const defaultContainerStyles: BoxProps["sx"] = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "50vh",
}

const MAX_RELOAD_ATTEMPTS = 3

export const LoadingAnimation = React.memo(
  ({
    text = "Carregando...",
    timeout = 4000,
    onTimeout,
    isVisible = true,
    autoRetry = false,
  }: LoadingAnimationProps): JSX.Element | null => {
    const [reloadAttempts, setReloadAttempts] = useState(0)

    const defaultTimeoutHandler = useCallback(() => {
      if (typeof window !== "undefined" && navigator.onLine) {
        setReloadAttempts((prev) => {
          if (prev >= MAX_RELOAD_ATTEMPTS) return prev
          const nextAttempt = prev + 1

          if (autoRetry) {
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          }

          return nextAttempt
        })
      }
    }, [autoRetry])

    const handleTimeout = onTimeout || defaultTimeoutHandler

    useEffect(() => {
      if (!isVisible) return
      const timer = setTimeout(handleTimeout, timeout)
      return () => clearTimeout(timer)
    }, [timeout, handleTimeout, isVisible])

    if (!isVisible) return null

    return (
      <Box sx={defaultContainerStyles} role="alert" aria-live="assertive">
        <CircularProgress
          variant="indeterminate"
          color="secondary"
          size={100}
          thickness={4}
          aria-label="Carregando"
          sx={{ color: "primary.main" }}
        />
        <Typography
          variant="body2"
          component="span"
          sx={{ marginTop: 2, textAlign: "center" }}
        >
          {text}
        </Typography>

        {reloadAttempts > 0 && (
          <Typography
            variant="body2"
            color="error"
            sx={{ marginTop: 1, textAlign: "center" }}
          >
            Tentativa {reloadAttempts} de {MAX_RELOAD_ATTEMPTS}
          </Typography>
        )}

        {!autoRetry && reloadAttempts > 0 && (
          <Button
            variant="contained"
            onClick={() => window.location.reload()}
            sx={{ marginTop: 2 }}
          >
            Tentar Novamente
          </Button>
        )}
      </Box>
    )
  },
)

LoadingAnimation.displayName = "LoadingAnimation"

export default LoadingAnimation
